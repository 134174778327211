import React from 'react';
import { FormattedMessage } from 'react-intl'
import ContentRender from '../ContentRenderer'
import { GrClose } from 'react-icons/gr';
import { ModalConfig } from '../types/modal';

interface ModalProps {
  config: ModalConfig
}

const Modal: React.FC<any> = (props) => {
  return (
    <div className="text-white text-justify fs-5">
      <div className="modal fade" id="modal" tabIndex={-1} aria-labelledby="modalLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen-sm-down modal-xl modal-dialog-centered">
          <div className="modal-content p-3 p-sm-4">
            <div className="modal-header p-0 mb-3">
              <h3 className="modal-title text-font fw-bolder" id="modalLabel">
                <FormattedMessage id={props.config.modalTitle} />
              </h3>
              <button className="btn btn-link p-0">
                <GrClose data-bs-dismiss="modal" aria-label="Close" size="30px" />
              </button>
            </div>
            <div className="modal-body text-font fs-5 text-justify p-0">
              <ContentRender content={props.config.content} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal;
