import React from 'react';

import Text from './elements/Text'
import Image from './elements/Image'
import Link from './elements/Link'
import Icon from './elements/Icon'
import Markdown from './elements/Markdown'
import GridEntry from './elements/GridEntry'
import Modal from './elements/Modal'

const ContentRenderer = (props) => {
  return (
    props.content.map((element, index) => {
      switch (element.type) {
        case 'text':
          return <Text key={props.itemkey + element.itemkey} config={element} lastChild={element.lastChild ? element.lastChild : !(index < props.content.length - 1)} />
        case 'image':
          return <Image key={props.itemkey + element.itemkey} config={element} />
        case 'link':
          return <Link key={props.itemkey + element.itemkey} config={element} />
        case 'icon':
          return <Icon key={props.itemkey + element.itemkey} config={element} />
        case 'markdown':
          return <Markdown key={props.itemkey + element.itemkey} config={element} />
        case 'gridEntry':
          return <GridEntry key={props.itemkey + element.itemkey} config={element} />
        case 'modal':
          return <Modal key={props.itemkey + element.itemkey} config={element} />
        default:
          return <p key="undefined">undefined</p>
      }

    })

  )
}

export default ContentRenderer;
