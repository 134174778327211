import React from 'react';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl'
import ContentRender from '../ContentRenderer'
import { Link as GatsbyLink } from 'gatsby'

import { LinkConfig } from '../types/link';

interface LinkProps {
  config: LinkConfig
}

const Link: React.FC<LinkProps> = (props) => {
  const intl = useIntl();
  return (
    <div>
      {(() => {
        switch (props.config.usage) {
          case 'internal':
            return (
              <GatsbyLink className={(clsx(
                "text-font text-decoration-none d-flex align-items-end link-hover",
                props.config.className
              ))}
                to={`/${intl.locale}${props.config.url}`}>

                <ContentRender content={props.config.content} />
              </GatsbyLink>
            )
          case 'external':
            return (
              <a href={props.config.url ? props.config.url : intl.formatMessage({ id: props.config.translation })} target="_blank" rel="noopener"
                className={(clsx(
                  "text-font text-decoration-none d-flex align-items-end link-hover",
                  props.config.className
                ))}>
                <ContentRender content={props.config.content} />
              </a>

            )
          default:
            return (<p>undefined</p>)
        }
      }

      )()}

    </div>

  )
}



export default Link;
