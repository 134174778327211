import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl'
import { TextConfig } from '../types/Text';

interface TextProps {
  config: TextConfig
  lastChild?: boolean
}

const Text: React.FC<TextProps> = (props) => {
  return (
    <div className={props.config.className}>
      {
        props.config.textSections.map((item, index) => {
          const removeMargin = props.lastChild && (index == props.config.textSections.length - 1)

          const translation =
            <FormattedMessage
              id={props.config.oneElementOnly ? props.config.translationPath : props.config.translationPath + ".text-" + index}
              values={{
                nbsp: <>&nbsp;</>,
                normal: (...chunks: any) => <span className="fw-normal">{chunks}</span>,
                bold: (...chunks: any) => <span className="fw-bold">{chunks}</span>,
                light: (...chunks: any) => <span className="fw-light">{chunks}</span>,
                italic: (...chunks: any) => <span className="fst-italic">{chunks}</span>,
                underline: (...chunks: any) => <span className="text-decoration-underline">{chunks}</span>,
                link: (...chunks: any) => <span className="">LINK</span>,
                modal: (...chunks: any) =>
                  <button type="button" className="btn align-baseline text-white fs-5 text-bold text-decoration-underline px-0 pt-2 pb-3" data-bs-toggle="modal" data-bs-target="#modal">
                    {chunks}
                  </button>,
                phone: (...chunks: any) => <span
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    window.location.href = "Tel: +49 631 627991-0";
                    e.preventDefault();
                  }}
                  tabIndex={0}
                  role="button"
                  onKeyDown={null}>{chunks}</span>,
                mail: (...chunks: any) => <span
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    window.location.href = "mailto:info@coneno.de";
                    e.preventDefault();
                  }}
                  tabIndex={0}
                  role="button"
                  onKeyDown={null}>{chunks}</span>,
              }}
            />

          switch (item["fontSize"]) {
            case ("h1"):
              return (
                <h1 key={props.config.itemkey + "." + index} className={(clsx("fw-bolder", removeMargin ? "m-0" : "mb-3", item["className"]))}>
                  {translation}
                </h1>)
            case ("h2"):
              return (
                <h2 key={props.config.itemkey + "." + index} className={(clsx("fw-bolder", removeMargin ? "m-0" : "mb-3", item["className"]))}>
                  {translation}
                </h2>)
            case ("h3"):
              return (
                <h3 key={props.config.itemkey + "." + index} className={(clsx("fw-bolder ", removeMargin ? "m-0" : "mb-3", item["className"]))}>
                  {translation}
                </h3>)
            case ("h4"):
              return (
                <h4 key={props.config.itemkey + "." + index} className={(clsx("fw-bolder", removeMargin ? "m-0" : "mb-3", item["className"]))}>
                  {translation}
                </h4>)
            case ("h5"):
              return (
                <h5 key={props.config.itemkey + "." + index} className={(clsx("fw-bolder", removeMargin ? "m-0" : "mb-3", item["className"]))}>
                  {translation}
                </h5>)
            case ("h6"):
              return (
                <h6 key={props.config.itemkey + "." + index} className={(clsx("fw-bolder", removeMargin ? "m-0" : "mb-3", item["className"]))}>
                  {translation}
                </h6>)
            default:
              return (
                <p key={props.config.itemkey + "." + index} className={(clsx("text-justify", removeMargin ? "m-0" : "", item["className"]))}>
                  {translation}
                </p>)
          }
        })
      }</div>)


}

export default Text;
