import React from 'react';
import clsx from 'clsx';
import ReactMarkdown from 'react-markdown';
import { useIntl } from 'react-intl'

import { MarkdownConfig } from '../types/markdown';

interface MarkdownProps {
  config: MarkdownConfig
}

const Markdown: React.FC<MarkdownProps> = (props) => {
  const intl = useIntl();
  const markdown = require(`../../markdowns/${intl.locale}${props.config.src}`).default

  return (
    <ReactMarkdown
      className={(clsx(
        "text-font",
        props.config.className
      ))}
      components={{
        h3: ({ node, ...props }) => <h3 className="mb-3" {...props} >{props.children}</h3>,
        h4: ({ node, ...props }) => <h4 className="mb-3 mt-4" {...props} >{props.children}</h4>,
        h5: ({ node, ...props }) => <h5 className="mb-2 mt-3" {...props} >{props.children}</h5>,
        h6: ({ node, ...props }) => <h6 className="mb-2 mt-3" {...props} >{props.children}</h6>,
        p: ({ node, ...props }) => <p className="text-justify mb-2" {...props} />
      }
      }>
      {markdown}
    </ReactMarkdown>
  )

}

export default Markdown;
