import clsx from 'clsx';
import React from 'react';
import { ImArrowRight2 } from "react-icons/im";
import { FormattedMessage } from 'react-intl'
import ContentRender from '../ContentRenderer'
import { GridEntryConfig } from '../types/gridEntry';
import Text from './Text';

interface GridEntryProps {
  config: GridEntryConfig
}

const GridEntry: React.FC<GridEntryProps> = (props) => {

  switch (props.config.layout) {
    case 'custom':
      return (
        <div className={props.config.wrapperClass}>
          <div className={props.config.className}>
            <ContentRender content={props.config.content} />
          </div>
        </div>
      )

    default:
      return (
        <div className={props.config.wrapperClass}>
          <div className={props.config.className}>
            {props.config.dateLocal ?
              <Text config={{ translationPath: props.config.dateLocal, textSections: [{ fontSize: "p", className: "text-dark-offset mb-2" }], oneElementOnly: true }}></Text> : null}
            {props.config.image ?
              <div style={{ height: "84px", width: "100%" }} className="mb-3">
                <img src={props.config.image} className="image-overflow-div" alt="Partner" />
              </div>
              : null}
            {props.config.title ?
              <Text config={{ translationPath: props.config.title, textSections: [{ fontSize: "h4" }], oneElementOnly: true }}></Text> : null}
            {props.config.text ?
              <Text config={{ translationPath: props.config.text, textSections: [{ fontSize: "p" }], oneElementOnly: true }} lastChild={props.config.link ? false : true}></Text> : null}
            {props.config.link ?
              <FormattedMessage id={props.config.link}>
                {link => <a href={link.toString()} target="_blank" rel="noreferrer" className="text-font fw-bold text-decoration-none link-hover link-line-break">
                  {link}<ImArrowRight2 className="ms-1 align-text-bottom" size={16} />
                </a>}
              </FormattedMessage>
              : null}
          </div>
        </div>
      )
  }

}

export default GridEntry;
