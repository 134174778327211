import React from 'react'
import clsx from 'clsx';
import ContentRender from './ContentRenderer'

const PageRenderer = ({ pageContext }) => {
  const pageContent = require(`../pages/configs/${pageContext.page.title}.json`)
  return (
    <div className="container-fluid">
      {pageContent.rows.map(row => {
        return (
          <div key={row.itemkey}
            className={(clsx(
              " ",
              row.className
            ))}>
            <div className="container-md">
              <div className="row d-flex justify-content-center">
                {row.columns.map(column => {
                  return (

                    <div key={column.itemkey}
                      className={(clsx(
                        "",
                        column.className
                      ))}>
                      {column.accentureGrid ?
                        <div className="row row-col-lg-2">
                          <ContentRender content={column.content} itemkey={row.itemkey + column.itemkey} />
                        </div>
                        :
                        <ContentRender content={column.content} itemkey={row.itemkey + column.itemkey} />}

                    </div>
                  )
                })}
              </div>
            </div>
          </div>

        )
      })
      }
    </div>

  )
}

export default PageRenderer
