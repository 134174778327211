import React from 'react';
import clsx from 'clsx';

import { ImageConfig } from '../types/image';

interface ImageProps {
  config: ImageConfig
}

const Image: React.FC<ImageProps> = (props) => {
  switch (props.config.usage) {
    //Image scales to keep div dimensions
    case 'image-overflow-div':
      return (
        <div style={{
          backgroundImage: `url(${props.config.src})`,
          minHeight: props.config.minHeight,
          height: "100%",
          width: "100%"
        }}
          className="image-overflow-div"
        ></div>
      )
    case 'quikflow-image-div':
      return (
        <div style={{
          backgroundImage: `url(${props.config.src}), linear-gradient(270deg, #d71246 0%, #9d1e8b 100%)`,
          minHeight: props.config.minHeight,
          height: "100%",
          width: "100%"
        }}
          className="image-contain-div"
        ></div>
      )
    //Fixed Image Dimensions
    default:
      return (<img
        key={props.config.itemkey}
        className={(clsx(
          "",
          props.config.className
        ))}
        src={props.config.src}
        width={props.config.width}
        height={props.config.width}
        alt={props.config.alt} />)
  }
}

export default Image;
