import React from 'react';
import clsx from 'clsx';
import { IconConfig } from '../types/icon';

import { HiPhone } from "react-icons/hi";
import { IoMdMail } from "react-icons/io";
import { ImArrowRight2 } from "react-icons/im";
import { FormattedMessage } from 'react-intl';

interface IconProps {
  config: IconConfig
}
const Text: React.FC<IconProps> = (props) => {
  return (
    <div
      className={(clsx("d-flex align-items-center p-0 ", props.config.className))}
    >
      {(() => {
        switch (props.config.icon) {
          case 'phone':
            return (
              <div
                className="d-flex align-items-center fs-5"
                onClick={(e) => {
                  window.location = props.config.onClick;
                  e.preventDefault();
                }}
                tabIndex={0}
                role="button"
                onKeyDown={null}
              >
                <HiPhone
                  className={props.config.translation ? "me-3" : ""}
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    window.location = props.config.onClick;
                    e.preventDefault();
                  }}
                  tabIndex={0}
                  role="button"
                  onKeyDown={null}
                  size={props.config.size}
                />
                {props.config.translation ?
                  <p className="m-0 fs-4 fw-bold">
                    <FormattedMessage id={props.config.translation} />
                  </p>
                  : null
                }
              </div>
            )
          case 'mail':
            return (
              <div
                className="d-flex align-items-center fs-5"
                onClick={(e) => {
                  window.location = props.config.onClick;
                  e.preventDefault();
                }}
                tabIndex={0}
                role="button"
                onKeyDown={null}
              >
                <IoMdMail
                  className={props.config.translation ? "me-3" : ""}
                  style={{ cursor: 'pointer' }}
                  size={props.config.size}
                />
                {props.config.translation ?
                  <p className="m-0 fs-4 fw-bold">
                    <FormattedMessage id={props.config.translation} />
                  </p>
                  : null
                }
              </div>
            )
          case 'arrow':
            return (
              <ImArrowRight2
                className=""
                size={props.config.size}
              />
            )
          default:
            return (<p>undefined</p>)
        }
      })()}

    </div>)


}

export default Text;
